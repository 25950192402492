import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

const selectContactUsPageDomain = (state: ApplicationRootState) => {
  return state ? state.contactUsPage : initialState;
};

const selectContactUsPage = () => createSelector(selectContactUsPageDomain, substate => substate);

export default selectContactUsPage;
export { selectContactUsPageDomain };
