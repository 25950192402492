/* tslint:disable */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.ContactUsForm';

export default defineMessages({
  owner: {
    id: `${scope}.owner`,
    defaultMessage: 'Chủ Doanh Nghiệp',
  },
  manager: {
    id: `${scope}.manager`,
    defaultMessage: 'Quản Lý',
  },
  purchaser: {
    id: `${scope}.purchaser`,
    defaultMessage: 'Thu Mua',
  },
  other: {
    id: `${scope}.other`,
    defaultMessage: 'Khác',
  },
  restaurant: {
    id: `${scope}.restaurant`,
    defaultMessage: 'Nhà Hàng',
  },
  cafe: {
    id: `${scope}.cafe`,
    defaultMessage: 'Cà Phê',
  },
  barAndClub: {
    id: `${scope}.barAndClub`,
    defaultMessage: 'Bar - Club',
  },
  hotel: {
    id: `${scope}.hotel`,
    defaultMessage: 'Khách Sạn',
  },
  reseller: {
    id: `${scope}.reseller`,
    defaultMessage: 'Cửa Hàng Tiện Lợi / Siêu Thị / Kinh Doanh Khác',
  },
  foodService: {
    id: `${scope}.foodService`,
    defaultMessage: 'Dịch Vụ Ăn Uống',
  },
  smallBusiness: {
    id: `${scope}.smallBusiness`,
    defaultMessage: 'Quán Ăn',
  },
  restaurantVietNamese: {
    id: `${scope}.restaurantVietNamese`,
    defaultMessage: 'Nhà Hàng Việt',
  },
  restaurantWestern: {
    id: `${scope}.restaurantWestern`,
    defaultMessage: 'Nhà Hàng Tây',
  },
  restaurantJapanese: {
    id: `${scope}.restaurantJapanese`,
    defaultMessage: 'Nhà Hàng Nhật Bản',
  },
  restaurantKorean: {
    id: `${scope}.restaurantKorean`,
    defaultMessage: 'Nhà Hàng Hàn Quốc',
  },
  restaurantChinese: {
    id: `${scope}.restaurantChinese`,
    defaultMessage: 'Nhà Hàng Trung Quốc',
  },
  restaurantThai: {
    id: `${scope}.restaurantThai`,
    defaultMessage: 'Nhà Hàng Thái',
  },
  restaurantVegetarian: {
    id: `${scope}.restaurantVegetarian`,
    defaultMessage: 'Nhà Hàng Chay',
  },
  restaurantChain: {
    id: `${scope}.restaurantChain`,
    defaultMessage: 'Chuỗi Nhà Hàng',
  },
  cafeBakeryIndependent: {
    id: `${scope}.cafeBakeryIndependent`,
    defaultMessage: 'Quán Cà Phê - Bánh',
  },
  cafeBakeryChain: {
    id: `${scope}.cafeBakeryChain`,
    defaultMessage: 'Chuỗi Cà Phê - Bánh',
  },
  barPub: {
    id: `${scope}.barPub`,
    defaultMessage: 'Bar - Pub',
  },
  nightClub: {
    id: `${scope}.nightClub`,
    defaultMessage: 'Hộp Đêm',
  },
  hotelOneToThreeStar: {
    id: `${scope}.hotel1To3Star`,
    defaultMessage: 'Khách Sạn 1 - 3 Sao',
  },
  hotelFourToFiveStar: {
    id: `${scope}.hotel4To5Star`,
    defaultMessage: 'Khách Sạn 4 - 5 Sao',
  },
  hotelNormal: {
    id: `${scope}.hotelNormal`,
    defaultMessage: 'Khách Sạn Bình Dân',
  },
  convenientStore: {
    id: `${scope}.convenientStore`,
    defaultMessage: 'Cửa Hàng Tiện Lợi',
  },
  superMarket: {
    id: `${scope}.superMarket`,
    defaultMessage: 'Siêu Thị',
  },
  fineRetail: {
    id: `${scope}.fineRetail`,
    defaultMessage: 'Fine Retail',
  },
  traditionalRetail: {
    id: `${scope}.traditionalRetail`,
    defaultMessage: 'Bán Lẻ Truyền Thống',
  },
  retailECommerce: {
    id: `${scope}.retailECommerce`,
    defaultMessage: 'Bản Lẻ E-com',
  },
  wholesaler: {
    id: `${scope}.wholesaler`,
    defaultMessage: 'Bản sỉ',
  },
  cateringAndCanteen: {
    id: `${scope}.cateringAndCanteen`,
    defaultMessage: 'Căn Tin',
  },
  factory: {
    id: `${scope}.factory`,
    defaultMessage: 'Nhà Máy',
  },
  school: {
    id: `${scope}.school`,
    defaultMessage: 'Trường Học',
  },
  hospital: {
    id: `${scope}.hospital`,
    defaultMessage: 'Bệnh Viện',
  },
  office: {
    id: `${scope}.office`,
    defaultMessage: 'Văn Phòng',
  },
  gym: {
    id: `${scope}.gym`,
    defaultMessage: 'Gym',
  },
  conventionCenter: {
    id: `${scope}.conventionCenter`,
    defaultMessage: 'Trung Tâm Hội Nghị - Tiệc Cưới',
  },
  takeAwayOnlineKitchen: {
    id: `${scope}.takeAwayOnlineKitchen`,
    defaultMessage: 'Bán Online - Mang Đi',
  },
  drinkRestaurant: {
    id: `${scope}.drinkRestaurant`,
    defaultMessage: 'Quán Nhậu',
  },
  foodStore: {
    id: `${scope}.foodStore`,
    defaultMessage: 'Quán cơm / Bún / Phở',
  },
  vipCustomer: {
    id: `${scope}.vipCustomer`,
    defaultMessage: 'Khách hàng Vip',
  },
  keyCustomer: {
    id: `${scope}.keyCustomer`,
    defaultMessage: 'Khách hàng quan trọng',
  },
  regularCustomer: {
    id: `${scope}.regularCustomer`,
    defaultMessage: 'Khách hàng thường xuyên',
  },
});
