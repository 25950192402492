import ContactUsForm from 'components/ContactUsForm';
import React, { FC, Fragment, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { compose, Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import translations from 'translations';
import messages from './messages';
import { connect } from 'react-redux';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import selectContactUsPage from './selectors';
import { checkEmailSubmit, contactUsSubmit } from './actions';
import reducer from './reducer';
import saga from './saga';
import { ContactUsForm as IContactUsForm, ContactUsPageState, RootState } from './types';
import useSessionStorage, { SessionStorageKey } from 'hooks/useSessionStorage';
import { BranchLinkSearch } from 'containers/MainLayout/types';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { triggerGA4SignUpEvent } from 'utils/triggerGA4Event';

interface IDispatchProps {
  onCheckEmail: (email: string) => void;
  onSubmit: (data: IContactUsForm) => void;
}

interface IStateProps {
  contactuspage: ContactUsPageState;
}

type Props = IStateProps & IDispatchProps & RouteComponentProps;

const ContactUsPage: FC<Props> = (props) => {
  const { contactuspage, onCheckEmail, onSubmit, ...rest } = props;
  const { isCheckingEmail, validationEmailMessage, isSuccess } = contactuspage;
  const [campaignSource, , removeCampaignSource] = useSessionStorage<BranchLinkSearch>(
    SessionStorageKey.CAMPAIGN_SOURCE,
  );

  const onSubmitContactUs = useCallback(
    (data: IContactUsForm) => {
      data.businessCategory = ''
      data.businessType = ''
      if(!data.email) {
        data.email = ''
      }
      onSubmit(data);
      triggerGA4SignUpEvent(data);
      if (window?.clarity) {
        window.clarity('set', 'sign_up', '1');
      }
      if (!!campaignSource) {
        removeCampaignSource();
      }
    },
    [campaignSource, removeCampaignSource, onSubmit],
  );

  return (
    <Fragment>
      <Helmet>
        <title>{`${translations(messages.header)} | Nhà Cung Cấp Kamereo`}</title>
        <meta name="description" content={`${translations(messages.header)}`} />
        <meta property="og:title" content={`${translations(messages.header)}`} />
        <meta name="og:description" content={`${translations(messages.header)}`} />
      </Helmet>
      <ContactUsForm
        isSuccess={isSuccess}
        onSubmit={onSubmitContactUs}
        onCheckEmail={onCheckEmail}
        isCheckingEmail={isCheckingEmail}
        validationEmailMessage={validationEmailMessage}
        {...rest}
      />
    </Fragment>
  );
};

const mapStateToProps = createStructuredSelector<RootState, IStateProps>({
  contactuspage: selectContactUsPage(),
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  onSubmit: (values: IContactUsForm) => dispatch(contactUsSubmit(values)),
  onCheckEmail: (email: string) => dispatch(checkEmailSubmit(email)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer<ContactUsPageState>({ key: 'contactUsPage', reducer: reducer });

const withSaga = injectSaga<ContactUsPageState>({ key: 'contactUsPage', saga: saga });

export default compose(withReducer, withSaga, withConnect, withRouter)(ContactUsPage);
