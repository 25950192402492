import translations from 'translations';
import {
  BusinessTypes,
  BusinessBarPubCategory,
  BusinessCafeCategory,
  BusinessFoodServiceCategory,
  BusinessHotelCategory,
  BusinessReSellerCategory,
  BusinessRestaurantCategory,
  BusinessSmallBusinessCategory,
  RepresentativePersonRoles,
  BuyerPriority,
  BusinessTeam,
} from '../../types/schema';
import messages from './messages';

export default {
  roleAtBusiness: [
    {
      id: RepresentativePersonRoles.Owner,
      value: RepresentativePersonRoles.Owner,
      name: translations(messages.owner),
    },
    {
      id: RepresentativePersonRoles.Manager,
      value: RepresentativePersonRoles.Manager,
      name: translations(messages.manager),
    },
    {
      id: RepresentativePersonRoles.Purchaser,
      value: RepresentativePersonRoles.Purchaser,
      name: translations(messages.purchaser),
    },
    {
      id: RepresentativePersonRoles.Other,
      value: RepresentativePersonRoles.Other,
      name: translations(messages.other),
    },
  ],
  priorityLevel: [
    {
      value: BuyerPriority.VipCustomer,
      label: translations(messages.vipCustomer),
    },
    {
      value: BuyerPriority.KeyCustomer,
      label: translations(messages.keyCustomer),
    },
    {
      value: BuyerPriority.RegularCustomer,
      label: translations(messages.regularCustomer),
    },
  ],
  team: [
    {
      value: BusinessTeam.Sme,
      label: 'SME',
    },
    {
      value: BusinessTeam.KaModernTrade,
      label: 'KA - Modern Trade',
    },
    {
      value: BusinessTeam.KaFoodChain,
      label: 'KA - Food Chain',
    },
    {
      value: BusinessTeam.KaSchoolFactory,
      label: 'KA - School & Factory',
    },
    {
      value: BusinessTeam.KaHotelConvention,
      label: 'KA - Hotel & Convention'
    }
  ],
  businessTypes: [
    {
      id: BusinessTypes.Restaurant,
      value: BusinessTypes.Restaurant,
      name: translations(messages.restaurant),
    },
    {
      id: BusinessTypes.Cafe,
      value: BusinessTypes.Cafe,
      name: translations(messages.cafe),
    },
    {
      id: BusinessTypes.BarClub,
      value: BusinessTypes.BarClub,
      name: translations(messages.barAndClub),
    },
    {
      id: BusinessTypes.Hotel,
      value: BusinessTypes.Hotel,
      name: translations(messages.hotel),
    },
    {
      id: BusinessTypes.Reseller,
      value: BusinessTypes.Reseller,
      name: translations(messages.reseller),
    },
    {
      id: BusinessTypes.FoodService,
      value: BusinessTypes.FoodService,
      name: translations(messages.foodService),
    },
    {
      id: BusinessTypes.SmallBusiness,
      value: BusinessTypes.SmallBusiness,
      name: translations(messages.smallBusiness),
    },
  ],
  [BusinessTypes.Restaurant]: [
    {
      id: BusinessRestaurantCategory.RestaurantVietnamese,
      value: BusinessRestaurantCategory.RestaurantVietnamese,
      name: translations(messages.restaurantVietNamese),
    },
    {
      id: BusinessRestaurantCategory.RestaurantWestern,
      value: BusinessRestaurantCategory.RestaurantWestern,
      name: translations(messages.restaurantWestern),
    },
    {
      id: BusinessRestaurantCategory.RestaurantJapanese,
      value: BusinessRestaurantCategory.RestaurantJapanese,
      name: translations(messages.restaurantJapanese),
    },
    {
      id: BusinessRestaurantCategory.RestaurantKorean,
      value: BusinessRestaurantCategory.RestaurantKorean,
      name: translations(messages.restaurantKorean),
    },
    {
      id: BusinessRestaurantCategory.RestaurantChinese,
      value: BusinessRestaurantCategory.RestaurantChinese,
      name: translations(messages.restaurantChinese),
    },

    {
      id: BusinessRestaurantCategory.RestaurantThai,
      value: BusinessRestaurantCategory.RestaurantThai,
      name: translations(messages.restaurantThai),
    },
    {
      id: BusinessRestaurantCategory.RestaurantVegetarian,
      value: BusinessRestaurantCategory.RestaurantVegetarian,
      name: translations(messages.restaurantVegetarian),
    },
    {
      id: BusinessRestaurantCategory.RestaurantChain,
      value: BusinessRestaurantCategory.RestaurantChain,
      name: translations(messages.restaurantChain),
    },
  ],
  [BusinessTypes.Cafe]: [
    {
      id: BusinessCafeCategory.CafeBakeryIndependent,
      value: BusinessCafeCategory.CafeBakeryIndependent,
      name: translations(messages.cafeBakeryIndependent),
    },
    {
      id: BusinessCafeCategory.CafeBakeryChain,
      value: BusinessCafeCategory.CafeBakeryChain,
      name: translations(messages.cafeBakeryChain),
    },
  ],
  [BusinessTypes.BarClub]: [
    {
      id: BusinessBarPubCategory.BarPub,
      value: BusinessBarPubCategory.BarPub,
      name: translations(messages.barPub),
    },
    {
      id: BusinessBarPubCategory.NightClub,
      value: BusinessBarPubCategory.NightClub,
      name: translations(messages.nightClub),
    },
  ],
  [BusinessTypes.Hotel]: [
    {
      id: BusinessHotelCategory.Hotel_1_3Star,
      value: BusinessHotelCategory.Hotel_1_3Star,
      name: translations(messages.hotelOneToThreeStar),
    },
    {
      id: BusinessHotelCategory.Hotel_4_5Star,
      value: BusinessHotelCategory.Hotel_4_5Star,
      name: translations(messages.hotelFourToFiveStar),
    },
    {
      id: BusinessHotelCategory.HotelNormal,
      value: BusinessHotelCategory.HotelNormal,
      name: translations(messages.hotelNormal),
    },
  ],
  [BusinessTypes.Reseller]: [
    {
      id: BusinessReSellerCategory.ConvenientStore,
      value: BusinessReSellerCategory.ConvenientStore,
      name: translations(messages.convenientStore),
    },
    {
      id: BusinessReSellerCategory.SuperMarket,
      value: BusinessReSellerCategory.SuperMarket,
      name: translations(messages.superMarket),
    },
    {
      id: BusinessReSellerCategory.FineRetail,
      value: BusinessReSellerCategory.FineRetail,
      name: translations(messages.fineRetail),
    },
    {
      id: BusinessReSellerCategory.TraditionalRetail,
      value: BusinessReSellerCategory.TraditionalRetail,
      name: translations(messages.traditionalRetail),
    },
    {
      id: BusinessReSellerCategory.RetailECommerce,
      value: BusinessReSellerCategory.RetailECommerce,
      name: translations(messages.retailECommerce),
    },
    {
      id: BusinessReSellerCategory.Wholesaler,
      value: BusinessReSellerCategory.Wholesaler,
      name: translations(messages.wholesaler),
    },
  ],
  [BusinessTypes.FoodService]: [
    {
      id: BusinessFoodServiceCategory.CateringAndCanteen,
      value: BusinessFoodServiceCategory.CateringAndCanteen,
      name: translations(messages.cateringAndCanteen),
    },
    {
      id: BusinessFoodServiceCategory.Factory,
      value: BusinessFoodServiceCategory.Factory,
      name: translations(messages.factory),
    },
    {
      id: BusinessFoodServiceCategory.School,
      value: BusinessFoodServiceCategory.School,
      name: translations(messages.school),
    },
    {
      id: BusinessFoodServiceCategory.Hospital,
      value: BusinessFoodServiceCategory.Hospital,
      name: translations(messages.hospital),
    },
    {
      id: BusinessFoodServiceCategory.Office,
      value: BusinessFoodServiceCategory.Office,
      name: translations(messages.office),
    },
    {
      id: BusinessFoodServiceCategory.Gym,
      value: BusinessFoodServiceCategory.Gym,
      name: translations(messages.gym),
    },
    {
      id: BusinessFoodServiceCategory.ConventionCenter,
      value: BusinessFoodServiceCategory.ConventionCenter,
      name: translations(messages.conventionCenter),
    },
  ],
  [BusinessTypes.SmallBusiness]: [
    {
      id: BusinessSmallBusinessCategory.TakeAwayOnlineKitchen,
      value: BusinessSmallBusinessCategory.TakeAwayOnlineKitchen,
      name: translations(messages.takeAwayOnlineKitchen),
    },
    {
      id: BusinessSmallBusinessCategory.DrinkRestaurant,
      value: BusinessSmallBusinessCategory.DrinkRestaurant,
      name: translations(messages.drinkRestaurant),
    },
    {
      id: BusinessSmallBusinessCategory.FoodStore,
      value: BusinessSmallBusinessCategory.FoodStore,
      name: translations(messages.foodStore),
    },
  ],
};
