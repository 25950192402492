import { action, createAsyncAction } from 'typesafe-actions';
import { ContactUsForm } from './types';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);

export const contactUsSubmit = (values: ContactUsForm) => action(ActionTypes.CONTACT_US_SUBMIT, values);

export const contactUs = createAsyncAction(
  ActionTypes.CONTACT_US_REQUEST,
  ActionTypes.CONTACT_US_SUCCESS,
  ActionTypes.CONTACT_US_FAILURE,
)<void, void, Error>();

export const checkEmailSubmit = (values: any) => action(ActionTypes.CHECK_EMAIL_SUBMIT, values);

export const checkEmail = createAsyncAction(
  ActionTypes.CHECK_EMAIL_REQUEST,
  ActionTypes.CHECK_EMAIL_SUCCESS,
  ActionTypes.CHECK_EMAIL_FAILURE,
)<void, {}, Error>();
