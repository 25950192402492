enum ActionTypes {
  DEFAULT_ACTION = 'app/ContactUsPage/DEFAULT_ACTION',
  CHECK_EMAIL_SUBMIT = 'app/ContactUsPage/CHECK_EMAIL_SUBMIT',
  CHECK_EMAIL_REQUEST = 'app/ContactUsPage/CHECK_EMAIL_REQUEST',
  CHECK_EMAIL_SUCCESS = 'app/ContactUsPage/CHECK_EMAIL_SUCCESS',
  CHECK_EMAIL_FAILURE = 'app/ContactUsPage/CHECK_EMAIL_FAILURE',
  CONTACT_US_SUBMIT = 'app/ContactUsPage/CONTACT_US_SUBMIT',
  CONTACT_US_REQUEST = 'app/ContactUsPage/CONTACT_US_REQUEST',
  CONTACT_US_SUCCESS = 'app/ContactUsPage/CONTACT_US_SUCCESS',
  CONTACT_US_FAILURE = 'app/ContactUsPage/CONTACT_US_FAILURE',
}

export default ActionTypes;
